.col-itp-values-config {
  div {
    margin: 10px 0;
    border-bottom: 1px solid $black;
  }

  &--info {
    span {
      font-style: italic;
    }
  }
}
