.col-img-decoration {
  margin-bottom: 10px;
  padding: 76px 118px;
  min-height: 500px;
  position: relative;
  background-color: $light-gray;

  @media #{$lg} {
    padding: 10px 10px 10px 10px;
  }

  @media #{$md} {
    padding: 10px;
  }
}

.col-bg-decoration-img {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  &--left {
    left: 0;
  }

  @media #{$md} {
    display: none;
  }
}
