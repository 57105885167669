$base-path:               '/webdav/files/System';
$font-path:               '#{$base-path}/resources/fonts';
$graphics-path:           '#{$base-path}/resources/graphics';
$icons-path:           '#{$graphics-path}/icons';
$fa-font-path: '#{$font-path}/font-awesome' !default;

$body-bg: $white;

// * Font, line-height, and color for body text, headings, and more. * //
$header-font:             'Gotham 8r', 'Gotham A', 'Gotham B';
$text-font:               'Gotham 4r', 'Gotham A', 'Gotham B';

$font-size-base:          15px;

// * Unit-less `line-height` for use in components like buttons. * //
$line-height-base:        1.5;

// * Grid *//
$xs-min: 0;
$sm-min: 480px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xs-max: ($sm-min - 1);
$sm-max: ($md-min - 1);
$md-max: ($lg-min - 1);
$lg-max: ($xl-min - 1);
$xs: '(max-width: #{$xs-max})';
$sm: '(max-width: #{$sm-max})';
$sm-strict: '(min-width: #{$sm-min}) and (max-width: #{$sm-max})';
$md: '(max-width: #{$md-max})';
$md-strict: '(min-width: #{$md-min}) and (max-width: #{$md-max})';
$lg: '(max-width: #{$lg-max})';
$lg-strict: '(min-width: #{$lg-min}) and (max-width: #{$lg-max})';
$xl: '(min-width: #{$xl-min})';

$grid-width: 1440px;
$grid-min: ($grid-width + 1);
$col-size: 12;
$col-spacing: 34px;
