.col-decoration-bullet-list {
  list-style: none !important;
  margin-left: 5px !important;

  @include no-list-style;

  ul {
    @include no-list-style;
  }

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    font-size: 18px;
    line-height: 1.56;

    &::before {
      content: "";
      width: 13px;
      height: 13px;
      background-color: $red;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
}

.col-decoration-bullet-list--green {
  @extend .col-decoration-bullet-list;

  li {
    @extend .col-icon;
    @extend .col-icon-check;

    padding-left: 55px;
    margin-bottom: 30px;

    &::before {
      content: none;
    }

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 33px;
      height: 33px;
    }
  }
}
