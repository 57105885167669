.col-responsible-editors {
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  &__pages {
    display: none;
  }

  &__page {
    border-bottom: 1px solid rgba(203, 203, 203, 0.5);

    &:first-child {
      border-top: 1px solid rgba(203, 203, 203, 0.5);
    }

    &:last-child {
      border-bottom: none;
    }

    &-name {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      padding: 12px;

      &:hover {
        .col-responsible-editors__page-name {
          text-decoration: underline;
        }
      }
    }

    &-modified {
      flex-wrap: nowrap;
      flex-shrink: 0;
      color: darken($dark, 30%);
    }

    &-namepath {
      display: block;
      padding: 0 12px 8px 12px;
      color: darken($dark, 30%);
    }
  }

  &__editor {
    align-items: center;
    background: #fff;
    border: 1px solid rgba(203, 203, 203, 0.5);
    margin-bottom: 8px;

    &-controls {
      position: relative;
      display: flex;
      align-items: center;
    }

    img {
      margin-left: 6px;
      border-radius: 50%;
      border: 2px solid #ebe8e1;
      width: 32px;
      height: 32px;
    }

    button {
      font-size: 15px;
      height: 48px;
      border: none;
      background: transparent;
      flex-grow: 1;
      text-align: left;
      display: flex;
      padding: 0 12px;
      min-width: 0;
      align-items: center;
      cursor: pointer;

      > span {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        font-size: 16px;
        position: absolute;
        right: 15px;
        top: 16px;
      }
    }

    &--expanded {
      button i {
        transform: rotate(180deg);
      }

      .col-responsible-editors {
        &__pages {
          display: block;
        }
      }
    }
  }

  &__pagecount {
    color: darken($dark, 30%);
  }

  &__mail {
    padding: 0 40px 0 20px;
  }
}
