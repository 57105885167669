.col-input-label {
  display: block;
  font-size: 14px;
  font-weight: 700;
}

.col-input-required-asterisk {
  &::after {
    content: '*';
    color: $red;
    font-size: 14px;
    margin-left: 2px;
  }
}

.col-input-text {
  @include placeholder { color: $form-placeholder; }
  @include transition('border, background-color, box-shadow');
  @include no-outline-focus;

  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
  font-family: $text-font;
  font-weight: 300;
  color: $charcoal-grey;
  height: 40px;
  border: 1px solid $form-input-border;
  border-radius: 8px;
  background-color: $form-input-bg;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 99% 8px;
  background-position: right 10px top 8px;

  &:focus {
    border-color: $focus-color;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.col-input-text-small {
  @extend .col-input-text;

  height: 35px;
  font-size: 12px;
}

.col-input-select {
  @extend .col-input-text;

  cursor: pointer;
  background-image: url('#{$graphics-path}/icons/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}

.col-input-select-small {
  @extend .col-input-select;

  font-size: 12px;
}

.col-input-text-invalid {
  border: solid 2px $notification-red;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 99% 8px;
  background-position: right 10px top 8px;
}

.col-input-textarea {
  @extend .col-input-text;

  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 120px;
  height: auto;
  max-width: 100%;
}

.col-input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.col-input-btn {
  @include no-appearance();
  @include outline-focus();

  min-height: 50px;
  background-color: $cyan;
  padding: 0 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  width: 100%;
  color: $white;
  cursor: pointer;
  border-radius: 22px;

  &:focus {
    color: $white;
    background-color: darken($cyan, 5%);
  }

  &:hover {
    color: $white;
    background-color: darken($cyan, 5%);
  }

  &:active {
    box-shadow: none;
    background-color: darken($cyan, 5%);
    color: $white;
  }
}

.col-input-checkbox {
  display: block;
  height: 0;
  opacity: 0;

  & + label::before {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: $white;
    border-radius: 4px;
    top: -1px;
    margin-right: 10px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;

    @include transition('color, background-color, border-color, border-width');
  }

  & + label {
    display: block;
    font-size: 14px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    min-height: 36px;
    padding-left: 42px;
  }

  &:checked + label::before {
    @extend .col-icon;
    @extend .col-icon-check-purple;

    background-color: $white;
  }

  &:focus + label::before {
    @include outline-inline-focus;
  }

  &:focus:checked + label::before {
    @include outline-inline-focus;

    border: 1px solid $form-input-focus-border;
  }
}

.col-input-radio {
  display: block;
  height: 0;
  opacity: 0;

  & + label {
    display: block;
    font-size: 14px;
    line-height: 40px;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;
    min-height: 40px;
    position: relative;
    padding-left: 46px;
  }

  // stylelint-disable-next-line no-descending-specificity
  & + label::before {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    background-color: $white;
    border: 9px solid $white;
    margin-right: 10px;
    text-align: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 0;
    @include transition('color, background-color, border-color, border-width');
  }

  // stylelint-disable-next-line no-descending-specificity
  &:checked + label::before {
    border-color: $white !important;
    background-color: $purple;
    border-width: 9px !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  &:focus + label::before {
    @include outline-inline-focus;
  }

  // stylelint-disable-next-line no-descending-specificity
  &:focus:checked + label::before {
    @include outline-inline-focus;

    border: 1px solid $form-input-focus-border;
  }
}
