.col-decoration-numbered-list {
  @include no-list-style;

  counter-reset: item;
  list-style-type: none;

  li {
    position: relative;
    display: block;
    margin-bottom: 23px;
    font-size: 18px;
    line-height: 1.56;
    color: $text-gray;
    padding-left: 40px;

    &::before {
      margin-right: 14px;
      color: $red;
      font-size: 14px;
      font-weight: 500;
      background-color: $warm-white;
      content: counter(item);
      counter-increment: item;
      line-height: 27px;
      width: 27px;
      height: 27px;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }
  }
}
