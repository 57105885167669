.col-top-links {
  &__item {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: $white;
    margin-left: 10%;

    @media #{$md} {
      margin-left: 25px;
    }
  }

  &__img {
    top: 2px;
    height: 19px;
    width: 19px;
    position: relative;
    margin-right: 3px;
  }
}
