.col-big-icon-app {
  position: relative;
  width: 142px;
  margin: 0 auto;
  border-radius: 50%;

  &__bg {
    content: '';
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 142px;
    height: 142px;
  }

  &__purple {
    background-color: $purple;

    &::before,
    &::after {
      background-color: $purple;
    }
  }

  &__blue {
    background-color: $cyan;

    &::before,
    &::after {
      background-color: $cyan;
    }
  }

  &__red {
    background-color: $red;

    &::before,
    &::after {
      background-color: $red;
    }
  }

  &__pink {
    background-color: $pink;

    &::before,
    &::after {
      background-color: $pink;
      border-color: $pink;
    }
  }

  &__icon {
    height: 80px;
    margin-top: 12px;
  }

  h2 {
    margin-bottom: 5px;
    font-size: 18px;
  }

  p {
    margin-top: 0;
    font-size: 15px;
  }

  &__hexagon {
    position: relative;
    height: 170px;
    width: 160px;

    &::before {
      border-top: 47px solid transparent;
      border-bottom: 47px solid transparent;
      border-right: 78px solid $light-gray-two;
      height: 85px;
      width: 0;
      margin: 0;
      position: absolute;
      top: -6px;
      bottom: -20px;
      left: 0;
    }

    &::after {
      border-top: 47px solid transparent;
      border-bottom: 47px solid transparent;
      border-left: 78px solid $light-gray-two;
      height: 85px;
      width: 0;
      margin: 0;
      position: absolute;
      top: -6px;
      left: 78px;
      bottom: -20px;
    }

    .col-big-icon-app__bg {
      display: block;
      width: unset;
      height: unset;

      img {
        position: absolute;
        z-index: 1;
        left: 40px;
        top: 30px;
      }
    }

    &.col-big-icon-app__purple {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
        border-right-color: $purple;
        border-left-color: darken($purple, 3%);
      }
    }

    &.col-big-icon-app__blue {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
        border-right-color: $cyan;
        border-left-color: darken($cyan, 3%);
      }
    }

    &.col-big-icon-app__green {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
        border-right-color: $teal;
        border-left-color: darken($teal, 3%);
      }
    }

    &.col-big-icon-app__red {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
        border-right-color: $red;
        border-left-color: darken($red, 3%);
      }
    }

    &.col-big-icon-app__pink {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
        border-right-color: $pink;
        border-left-color: darken($pink, 3%);
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
    }
  }
}
