.col-mega-menu-sub-item {
  @include no-appearance();

  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.29;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-decoration: none;
  height: 60px;
  padding: 0 40px;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover {
    color: $white;
    background-color: $darker-red;
  }

  i.fal,
  i.far,
  i.fas,
  i.fab {
    font-size: 20px;
    margin-right: 14px;
  }

  i.fal.fa-chevron-right,
  i.far.fa-chevron-right,
  i.fas.fa-chevron-right {
    font-size: 16px;
    position: absolute;
    right: 16px;
    top: 23px;
    margin-right: 0;
    opacity: 0.5;
    color: $warm-white;
  }

  &--current {
    text-decoration: underline;
  }

  &--expanded {
    background-color: $darker-red;
    color: $white;
    box-shadow: -7px 4px 13px 0 rgba(0, 0, 0, 0.08);
    border-bottom-color: transparent;
    z-index: 3;

    i.fal.fa-chevron-right,
    i.far.fa-chevron-right {
      opacity: 0;
    }

    &:hover {
      color: $white;
    }
  }

  [data-whatinput=keyboard] &:focus {
    outline-offset: 0;
  }
}
