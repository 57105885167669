.col-documentsarchive-item {
  background-color: $white;
  margin-bottom: 25px;
  padding: 22px 25px;

  &__name {
    margin-bottom: 16px;

    span {
      font-size: 14px;
      font-weight: 900;
      line-height: 1;
      color: $charcoal-grey;
    }
  }

  &__desc {
    margin-bottom: 21px;

    span {
      font-size: 15px;
      line-height: 1.33;
      color: $text-gray;
    }
  }

  &__download {
    display: inline-block;
    min-width: 141px;
    margin: 5px 0;

    & a {
      text-decoration: none;
      font-size: 14px;
      line-height: 1.29;
      color: $warm-black;
    }

    & i {
      padding: 4px 6px;
      background-color: $cyan;
      border-radius: 50%;
      display: table-cell;
    }
  }

  &__service {
    display: inline-block;
    min-width: 141px;
    margin: 5px 0;

    & a {
      text-decoration: none;
      font-size: 14px;
      line-height: 1.29;
      color: $warm-black;
    }

    & i {
      display: table-cell;
      padding: 8px 6px 2px 6px;
      background-color: #f5f3f0;
      border-radius: 50%;

      &::after {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__printed {
    display: inline-block;
    min-width: 141px;
    margin: 5px 0;

    &--open {
      @include no-appearance();

      text-decoration: none;
      font-size: 14px;
      line-height: 1.29;
      color: $warm-black;
      padding: 0;
    }

    & i {
      padding: 8px 6px 2px 6px;
      background-color: #f5f3f0;
      border-radius: 50%;

      &::after {
        width: 14px;
        height: 14px;
      }
    }
  }
}
