.col-mobile-header {
  form {
    > div[role="search"] {
      // Behållare
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;

      .searchInputField {
        // Sökfältet
        flex: 1 0 auto;
        padding: 5px 15px;
        border: 0;
        border-radius: 2px;
        max-width: 98%;
        font-size: 15px;
        letter-spacing: 0;
        color: $search-gray;
        font-family: $text-font;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          // IE10 and above
          width: 100%;
        }
      }

      .searchSubmitButton {
        // Sökknappen
        flex: 0 0 auto;
        position: absolute;
        right: 15px;
        background-color: $red;
        color: white;
        padding: 5px 15px;
        border: 0;
        border-radius: 3px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
