/* lessfile: modal.scss */

$modal-prefix: sol-modal;
$modal-bg: $white;

.#{$modal-prefix}-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
}

.#{$modal-prefix}-wrapper {
  display: table-cell;
  vertical-align: top;
}

.#{$modal-prefix}-container {
  @include no-outline-focus;

  width: 760px;
  margin: 5vh auto 0;
  max-height: 90vh;
  transition: all 500ms ease;
  background: white;
  position: relative;

  @media #{$md} {
    width: 95%;
  }

  @media #{$sm} {
    margin-top: 35px;
  }
}

.#{$modal-prefix}-content {
  padding: 36px 45px 36px 45px;
  max-height: 90vh;
  overflow-y: auto;

  @media #{$md} {
    padding: 36px 29px 36px 29px;
  }

  @media #{$sm} {
    padding: 36px 14px 36px 14px;
  }

  h1 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 20px;

    @media #{$sm} {
      display: block;
      margin-bottom: 10px;
      font-size: 22px;
    }
  }

  h2 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 800;
  }
}

.#{$modal-prefix}-close {
  @include no-appearance;
  @include outline-focus;

  position: absolute;
  top: 10px;
  right: 10px;
  color: $black;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  cursor: pointer;

  @media #{$sm} {
    top: 0;
    right: 2.5%;
    font-size: 14px;
    flex-direction: row;
    height: 35px;
  }

  & i.fa,
  & i.far,
  & i.fal,
  & i.fab,
  & i.fas {
    font-size: 32px;
    margin-bottom: 5px;

    @media #{$sm} {
      font-size: 25px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

.#{$modal-prefix}-transition-enter,
.#{$modal-prefix}-transition-leave-to {
  opacity: 0;
}

.#{$modal-prefix}-transition-enter-active,
.#{$modal-prefix}-transition-leave-active {
  transition: opacity 500ms ease;
}

.#{$modal-prefix}-transition-enter .#{$modal-prefix}-container {
  transform: scale(1.1);
}

.#{$modal-prefix}-transition-leave-active .#{$modal-prefix}-container {
  transform: scale(1.1);
}
