.col-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
}

.col-breadcrumbs {
  @include no-list-style;

  display: flex;
  margin-top: 24px;
  margin-bottom: 34px;
  line-height: 14px;

  .col-breadcrumb-white {
    display: none;
  }

  & a,
  &--active,
  &--folder {
    text-decoration: none;
    color: $black;
    font-size: 12px;
    font-weight: 300;
  }
  &--active {
    margin: 0;
    color: $inactive-gray;
  }

  li:not(:first-child) a {
    text-decoration: underline;
  }

  .col-divider {
    color: $black;
    font-size: 12px;
    font-weight: 300;
    margin: 0 5px;
    line-height: 18px;

    &:last-of-type {
      display: none;
    }
  }

  &__home {
    width: 14px;
    height: 14px;
  }
}
