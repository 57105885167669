.col-header-top {
  .sv-searchform-portlet {
    form {
      > div[role="search"] {
        // Behållare
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .searchInputField {
          // Sökfältet
          flex: 1 0 auto;
          padding: 5px 15px;
          border: 0;
          border-radius: 2px;
          height: 40px;
          font-size: 15px;
          letter-spacing: 0;
          color: $search-gray;
          font-family: $text-font;
        }

        .searchSubmitButton {
          // Sökknappen
          width: 20px;
          height: 20px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}

.sv-autocomplete-search-result {
  .sv-autocomplete-result-item {
    padding: 10px 20px !important;
    border-bottom: 1px solid $warm-white !important;

    &:last-child {
      border: 0 !important;
    }
  }
}

/*
.col-top-search-field {
  max-height: 50px;

  &__button {
    height: 44px;
    width: 71px !important;
    bottom: 47px;
    right: 4px;
    border-radius: 2px;
    font-size: 14px;
    border: 0;
    position: relative;
    background-color: $red;
    color: $white;
    display: none;
  }

  &__img {
    height: 19px;
    width: 20px !important;
    top: -36px;
    right: 13px;
    position: relative;
  }
}

.sv-searchform2-message {
  text-align: left;
  font-size: 16px;
}
*/
