$banner-height: 411px;

.col-top-banner__wrapper {
  overflow: hidden;
  position: relative;
  min-height: $banner-height;
  background-repeat: no-repeat;
  background-position: right;

  .col-grid {
    max-width: 1180px;
  }

  @media #{$md} {
    min-height: 511px;
  }

  .col-hide-mobile {
    @media #{$md} {
      display: none;
    }
  }

  @media #{$md} {
    display: flex;
    align-items: flex-end;
  }

  .col-top-banner-img {
    min-height: 411px;
    width: 55%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;

    @media #{$md} {
      width: 100%;
    }
  }
}

.col-parallelogram {
  width: 70%;
  height: $banner-height;
  margin: 0 0 0 -185px;
  -webkit-transform: skew(150deg);
  transform: skew(155deg);
  background: $darkest-red;
  position: relative;
  z-index: 2;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 85px 85px 7px;
    border-color: transparent transparent #bc273b transparent;
    display: block;
    position: absolute;
    bottom: 0;
    right: -105px;
  }

  @media #{$md} {
    display: none;
    width: 100%;
  }
}

.col-top-banner {
  position: absolute;
  top: 0;
  left: 70px;
  right: -30px;
  bottom: -30px;
  transform: skew(-5deg);
  background-size: cover;
}

.col-top-banner--mobile {
  display: none;

  @media #{$md} {
    position: relative;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $red;
    transform: rotate(-10deg);
    margin-right: -40px;
    margin-left: -40px;
    margin-bottom: -80px;
    padding-bottom: 80px;
    min-height: 330px;
    width: 115%;

    &__content {
      transform: rotate(10deg);
      padding: 45px 25px 30px 40px;
      margin-bottom: 10px;
      margin-top: 15px;
    }
  }

  @media #{$sm} {
    &__content {
      padding: 40px 25px 30px 40px;
    }
  }

  @media #{$xs} {
    width: unset;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 85px 85px 25px;
    border-color: transparent transparent #bc273b transparent;
    display: block;
    position: absolute;
    top: -85px;
  }
}
