.col-person-list {
  display: flex;
  flex-wrap: wrap;

  a {
    color: $cyan;
    word-break: break-word;
  }

  img {
    max-width: 190px;
    max-height: 210px;
  }

  .col-person-list-item {
    width: 33%;
    padding: 26px;
    font-size: 18px;
    line-height: 1.45;
    text-align: center;
    color: $text-gray;

    @media #{$md} {
      width: 50%;
    }

    @media #{$xs} {
      width: 100%;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    text-align: center;
    color: $charcoal-grey;
    margin: 0.75em 0;
  }

  .info {
    margin: 0.75em 0;
  }
}

a.download-link {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $warm-black;
  text-decoration: none;

  .col-icon {
    padding: 0 5px 5px 5px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: $cyan;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
  }
}

.col-management-wrapper {
  &.sv-grid-main.sv-fluid-grid,
  .sv-layout.sv-column-12,
  .sv-layout.sv-column-6 {
    padding: 0;
  }

  @media #{$sm} {
    .font-description {
      font-size: 18px;
    }

    .font-heading-1 {
      font-size: 20px;
    }
  }

  .col-tabs__buttons {
    justify-content: center;

    @media #{$sm} {
      display: flex;
    }
  }

  .col-tabs-button {
    flex: 0 1 auto;
    min-width: 225px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;

    &--active {
      color: $red;
      font-weight: bold;
      border-color: $red;
    }

    @media #{$sm} {
      font-size: 14px;
      min-width: unset;
      word-break: break-all;
      padding: 0;
      width: 100px;
    }
  }
}
