.col-top-menu,
.col-mega-menu {
  background-color: $darker-red;
  position: relative;

  &__link {
    background-color: $darkest-red;
    color: $white;
    border: none;
    width: 160px;
    font-size: 15px;
    font-family: $text-font;
    padding: 10px;
    text-align: center;
    text-decoration: none;

    & > img {
      height: 19px;
      width: 15px;
      position: relative;
      top: 2px;
      margin-right: 8px;
    }
  }

  .col-grid {
    flex: 1 1 auto;

    & > ul {
      @include no-list-style;

      display: flex;

      & li:last-of-type {
        a {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.col-mega-menu__link {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
}
