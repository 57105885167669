@mixin clearfix() {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder { @content; }
  ::-moz-placeholder { @content; }
  :-moz-placeholder { @content; }
  :-ms-input-placeholder { @content; }
}

@mixin transition($property, $duration: 200ms, $style: ease) {
  -webkit-transition-property: unquote($property);
  transition-property: unquote($property);
  -webkit-transition-duration: $duration;
  transition-duration: $duration;
  -webkit-transition-timing-function: $style;
  transition-timing-function: $style;
}

@mixin no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
}

@mixin no-appearance-sc() {
  @include no-appearance;

  text-shadow: none;
  background-image: none;
  box-shadow: none;
  padding: 0;
  line-height: $line-height-base;

  &:hover {
    background: none;
    border: none;
  }

  &:active {
    background: none;
    box-shadow: none;
  }
}

@mixin no-list-style() {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin fal() {
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-weight: 300;
}

@mixin far() {
  font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', sans-serif;
  font-weight: 400;
}

@mixin fas() {
  font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', sans-serif;
  font-weight: 900;
}

@mixin fab() {
  font-family: 'Font Awesome 5 Brands', sans-serif;
}

@mixin icon($icon, $size: inherit, $position: after, $style: regular) {
  display: inline-block;
  font-size: $size;

  @if $style == regular {
    @include far;
  }
  @else if $style == solid {
    @include fas;
  }
  @else if $style == light {
    @include fal;
  }
  @else if $style == brand {
    @include fab;
  }

  @if $position == after {
    &::after {
      content: fa-content($icon);
    }
  }
  @else if $position == before {
    &::before {
      content: fa-content($icon);
    }
  }
  @else {
    content: fa-content($icon);
  }
}

@mixin no-outline() {
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  z-index: inherit;
}

@mixin outline() {
  outline: 2px solid $focus-color;
  outline-offset: -2px;
  z-index: 10;
}

@mixin outline-inline-focus() {
  &,
  [data-whatinput="keyboard"] & {
    @include outline;
  }

  [data-whatinput="initial"] &,
  [data-whatinput="mouse"] &,
  [data-whatinput="touch"] & {
    @include no-outline;
  }
}

@mixin no-outline-focus() {
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}

@mixin outline-focus() {
  &:focus,
  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid $focus-color;
    outline-offset: -2px;
    z-index: 10;
  }

  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    z-index: inherit;
  }
}

@mixin remove-glyphicon() {
  background-image: none !important;
  width: auto;
  height: auto;
  vertical-align: middle;
  margin: 0;
  line-height: 1;
  font-style: normal;
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
