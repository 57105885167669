.col-documentarchive {
  &--no-children {
    padding-top: 25px;
  }

  &-choises {
    background-color: $white;
    padding: 22px 38px;
    margin-bottom: 15px;

    @media #{$md} {
      margin: 15px;
    }

    button {
      @include no-appearance();

      background-color: $purple;
      color: $white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 25px;
      padding: 15px 22px;
    }

    &__name {
      margin-bottom: 4px;

      span {
        font-size: 18px;
        font-weight: 900;
        line-height: 1.33;
        letter-spacing: 0.2px;
        color: $charcoal-grey;
      }
    }

    &__desc {
      margin-bottom: 25px;

      span {
        color: $text-gray;
        font-size: 18px;
        line-height: 1.56;
      }
    }
  }
}
