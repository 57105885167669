.col-mega-menu-link-item {
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
  max-width: 90%;
  text-decoration: none;
  color: $warm-black;

  &:hover {
    color: $black;
    text-decoration: underline;
  }

  [data-whatinput=keyboard] &:focus {
    outline-offset: 2px;
  }
}
