html {
  font-size: $font-size-base;
}

body {
  background-color: $body-bg;

  & > div.sv-layout,
  & > div.sv-layout > div {
    overflow-x: hidden;
  }
}
