.col-press-releases {
  .sv-channel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media #{$sm} {
      padding: 25px;
    }

    .sv-channel-item {
      width: 45%;

      & a {
        display: block;
        text-decoration: none;
        background-color: $white;
        box-shadow: 2px 2px 10px 0 rgba(37, 37, 37, 0.05);
        padding: 27px 37px;
      }

      &:nth-child(odd) {
        margin-right: 35px;
      }

      .col-press-release {
        &-date {
          text-transform: capitalize;
          color: $dark;
          line-height: 1.29;
          font-size: 14px;
        }

        &-header {
          font-weight: 900;
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: 0.2px;
          color: $charcoal-grey;
          margin-top: 9px;
          margin-bottom: 10px;
        }

        &-desc {
          font-size: 18px;
          line-height: 1.56;
          color: $text-gray;
        }
      }

      @media #{$sm} {
        width: 100%;

        &:nth-child(odd) {
          margin-right: 0;
        }
      }
    }
  }
}
