.col-tabs {
  margin-bottom: 50px;

  &__buttons {
    @include no-list-style;

    display: flex;

    @media #{$sm} {
      display: block;
      border-bottom: none;
    }

    & > button {
      flex: 1;

      @media #{$sm} {
        width: 100%;
      }
    }
  }

  &__tab {
    @include outline-focus;

    background-color: $light-gray;
    padding: 80px;
    margin-top: 4px;
    position: relative;

    @media #{$sm} {
      padding: 10px 0;
    }
  }

  &__content {
    & > div {
      top: 0;
      width: 100%;
    }
  }

  .col-geek__box {
    background-color: inherit;
    padding: 0;
  }
}

.col-tabs-button {
  @include outline-focus;
  @include no-appearance;

  position: relative;
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none;
  min-height: 44px;
  line-height: 44px;
  border-bottom: 6px solid transparent;
  margin-bottom: -4px;
  color: $inactive-gray;

  @media #{$sm} {
    padding-top: 5px;
  }

  &--active {
    border-bottom-color: $purple;
    color: $purple;
    font-weight: bold;
  }
}

.col-tabs-has-icon {
  justify-content: center;

  .col-tabs__tab {
    min-height: 350px;
    padding: 40px 143px;

    @media #{$md} {
      padding: 40px 0;
    }
  }

  .col-tabs-button {
    font-size: 16px;
    font-weight: normal;
    color: $inactive-gray;
    min-width: 140px;
    padding-top: 50px;
    padding-bottom: 7px;
    flex: 0;
    margin: 0 0 -3px 0;
    line-height: 16px;

    @media #{$sm} {
      display: inline-block;
      width: 100%;
    }

    & > button {
      flex: 0;
      margin: 0 25px -3px 0;
      min-width: 225px;
    }

    .col-tab-icon {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      opacity: 0.7;
      height: 34px;
      width: 34px;

      & path {
        stroke: $inactive-gray;
      }
    }

    &:not(:last-of-type) {
      margin-right: 80px;
    }

    &--active {
      font-weight: bold;
      color: $purple;

      .col-tab-icon {
        -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(0); /* Microsoft Edge and Firefox 35+ */
        opacity: 1;

        & path {
          stroke: $purple;
        }
      }
    }
  }
}

.col-documentarchive__wrapper {
  .col-tabs-button--active {
    border-bottom-color: $red;
    color: $red;

    .col-tab-icon {
      & path {
        stroke: $red;
      }
    }
  }
}
