.col-product-page-top {
  position: relative;

  .col-grid {
    max-width: 1180px;
  }

  & .col-breadcrumbs {
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 75px;
    margin: 0;

    span,
    a,
    li {
      color: $white;
    }

    .col-breadcrumb-white {
      display: initial;
    }

    .col-breadcrumb-black {
      display: none;
    }

    @media #{$md} {
      display: none;
    }
  }
}
