// Colors
$white:                 #fff;
$light-gray:            #faf8f4;
$light-gray-two:        #d5d3c9;
$lighter-gray:          #eae6da;
$extra-light:           #f7f6f3;
$lighter-table:         #f3f2ee;
$lighter:               #f0f0f0;
$black:                 #000;
$red:                   #ec0532;
$header-red:            #d0021b;
$warm-white:            #ebe8e1;
$cyan:                  #09c;
$warm-black:            #252525;
$pink:                  #f04e6a;
$purple:                #8a4979;
$dark-purple:           #793c69;
$purple-2:              #904979;
$dis:                   #a5dded;
$pear:                  #e8ea83;
$leaf:                  #6cc06d;
$mint:                  #00a88c;
$lavend:                #b5b0d8;
$plum:                  #67518d;
$orange:                #fcb11f;
$citron:                #ffe046;
$lime:                  #b2d788;
$mango:                 #489058;
$darker-red:            #d4062e;
$darkest-red:           #b4000c;
$charcoal-grey:         #2d2f33;
$notification-yellow:   #ffd75e;
$notification-red:      #bf2348;
$notification-green:    #65d1a8;
$dark:                  #acacac;
$bruise:                #793c69;
$focus-color:           #257cc3;
$text-black:            #252525;
$search-gray:           #909090;
$teal:                  #00a98c;
$text-gray:             #3a3d46;
$form-placeholder:      #8f8f8f;
$form-input-border:     #f7f7f7;
$form-input-bg:           $white;
$form-input-chk-empty-border: #b4b4b4;
$form-input-focus-border: $focus-color;
$inactive-gray:         #767676;
