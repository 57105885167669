.col-wide-image .sv-decoration-content img {
  margin-left: -150px;
  width: calc(100% + 300px);
  max-width: calc(100% + 300px);
  max-height: 600px;
  margin-bottom: 20px;
  object-fit: cover;
  object-position: 50%;
  font-family: 'object-fit: cover; object-position: 50%;', sans-serif;

  @media #{$sm} {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }
}
