.col-cookie-message {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  background-color: $warm-black;
  padding: 22px 0;
  color: $white;
  font-size: 14px;
  line-height: 1.29;

  a {
    color: $white;
  }

  button {
    @include no-appearance;

    margin-left: 30px;
    background-color: $pear;
    padding: 15px 25px;
    color: $warm-black;
    font-size: 11px;
    border-radius: 25px;
    font-weight: bold;
    line-height: 0.91;

    @media #{$md} {
      display: block;
      margin: 15px auto 0 auto;
    }
  }
}
