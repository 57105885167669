@import 'abstracts/**/*';
@import 'base/**/*';
@import 'layout/**/*';
@import 'components/**/*';
@import 'decorations/**/*';
@import 'pages/**/*';
@import 'templates/**/*';
@import 'vendor/*';

body,
html {
  font-family: $text-font;
  font-weight: 400;
}
