.col-scroll-to {
  &--container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: gray;
  }

  &--background-container {
    flex: 1 0 auto;
    height: 45px;
  }

  &--button-container {
    border-radius: 0 0 50% 50%;
    width: 90px;
    height: 90px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--button {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transform: rotate(90deg);

    .col-icon::after {
      width: 22px;
      height: 23px;
    }
  }
}
