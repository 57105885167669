.col-top-banner {
  &__content {
    position: absolute;
    top: 90px;
    width: 35%;
    z-index: 2;
  }

  &__heading {
    color: $white;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.1;
    letter-spacing: 0.22px;
    margin-bottom: 29px;

    @media #{$sm} {
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 10px;
    }
  }

  &__preamble {
    font-size: 16px;
    font-weight: 300;
    color: $white;
    line-height: 1.5;

    @media #{$sm} {
      font-size: 14px;
      line-height: 1.29;
    }
  }
}
