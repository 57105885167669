.col-geek {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;

  > .sv-script-portlet {
    text-align: center;
  }

  .col-line__box {
    display: none;
    position: absolute;
    top: 50%;
    right: 80px;
    left: 80px;
    z-index: 10;
    height: 2px;
    background-color: $warm-white;

    &--active {
      display: block;
    }

    @media #{$sm} {
      display: none;
    }
  }

  &__box {
    position: relative;
    top: 0;
    z-index: 20;
    display: inline-block;
    margin: 30px 0;
    background-color: $white;
    text-align: center;
    border-left: 15px solid white;
    border-right: 15px solid white;

    &--active {
      background-color: transparent;
      border: 0;
    }

    @media #{$sm} {
      margin: 0 auto;
      padding: 0;
    }
  }

  &__header {
    position: relative;
    border: none;
    background: $purple;
    height: 64px;
    max-height: 64px;
    color: white;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    font-family: $text-font;

    @media #{$sm} {
      max-width: 90%;
      padding: 0 15px;
      font-size: 15px;
      max-height: 50px;
      height: 50px;
    }

    &__icon {
      display: block;
      width: 28px;
      height: 33px;
      position: absolute;
      right: 10px;
      top: 53%;
      margin-top: -17px;
      opacity: 0.6;

      @media #{$sm} {
        width: 28px;
        height: 16px;
        position: absolute;
        right: 6px;
        top: 70%;
        margin-top: -16px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: $white;
        position: absolute;
        top: 50%;
        left: -10px;
        width: 20px;
        height: 3px;
        margin-top: -3px;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;

        @media #{$sm} {
          width: 28px;
          height: 2px;
          margin-top: -3px;
        }
      }

      &::after {
        transform: rotate(90deg);
        height: 3px;
      }
    }

    &--active {
      & .col-geek__header__icon {
        &::after {
          @include rotate(0deg);
        }
      }
    }
  }

  &__name {
    display: block;
    margin-right: 40px;

    @media #{xs} {
      width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sv-decoration-content {
    display: none;
    background-color: $light-gray;
    position: relative;
    top: -65px;
    z-index: 0;
    padding: 40px 20px 110px 20px;
  }

  &__bottom {
    display: none;
    position: relative;
    top: -97px;
    border: none;
    background: $purple;
    height: 64px;
    max-height: 64px;
    color: white;
    border-radius: 28px;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    font-family: $text-font;
    margin: 0 auto;

    @media #{$sm} {
      max-width: 90%;
      padding: 0 15px;
      font-size: 15px;
      max-height: 50px;
      height: 50px;
      top: -25px;
    }

    &__icon {
      display: block;
      width: 28px;
      height: 33px;
      position: absolute;
      right: 10px;
      top: 53%;
      margin-top: -17px;
      opacity: 0.6;

      @media #{$sm} {
        width: 28px;
        height: 16px;
        position: absolute;
        right: 6px;
        top: 70%;
        margin-top: -16px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: $white;
        position: absolute;
        top: 50%;
        left: -10px;
        width: 20px;
        height: 3px;
        margin-top: -3px;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;

        @media #{$sm} {
          width: 28px;
          height: 2px;
          margin-top: -3px;
        }
      }

      &::after {
        transform: rotate(90deg);
        height: 3px;
      }
    }

    &--active {
      display: block;

      & .col-geek__bottom__icon {
        &::after {
          @include rotate(0deg);
        }
      }
    }
  }
}
