.col-documentsarchive-order {
  max-width: 438px;
  padding: 66px 31px;
  margin: 0 auto;

  @media #{$sm} {
    padding: 66px 0;
  }

  form {
    border-radius: 2px;
    background-color: $lighter-table;
    padding: 21px;

    button[type="submit"] {
      width: 140px;
      display: block;
      margin-left: auto;
      margin-top: 19px;
    }

    input[type="text"] {
      margin-top: 14px;
      margin-bottom: 23px;
    }
  }

  &--items {
    .col-input-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    input[type="number"] {
      width: 85px;
    }
  }

  &--success {
    background-color: $white;
    text-align: center;
    padding: 65px 40px;

    &__heading {
      display: block;
      font-size: 18px;
      font-weight: 900;
      color: $charcoal-grey;
      line-height: 1.33;
    }

    &__delivery {
      display: block;
      font-size: 18px;
      color: $text-gray;
    }

    img {
      display: block;
      margin: 39px auto 0 auto;
      max-height: 210px;
      max-width: 210px;
    }
  }
}
