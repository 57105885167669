* {
  box-sizing: border-box;
}

a,
button {
  @include outline-focus;

  cursor: pointer;
}

.sr-only {
  @include sr-only();
}

[data-is-focused='true'] {
  &,
  [data-whatinput='keyboard'] & {
    outline: 2px solid $focus-color;
    z-index: 10;
  }

  a:first-of-type:focus,
  [data-whatinput='initial'] &,
  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    box-shadow: none;
    outline: none;
  }
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

select::-ms-expand {
  display: none;
}

.col-hidden {
  display: none;
}

.col-paddingless {
  padding: 0 !important;
}

.col-justify-center {
  justify-content: center !important;
}

.sv-linkicon {
  max-width: 25px;
  max-height: 13px;
  padding-left: 5px;
}
