.col-page-feedback {
  &--main-title {
    margin-bottom: 19px;
    height: 24px;
    font-size: 18px;
    font-weight: 900;
  }

  &--information-title {
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  &--information-text {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #3a3d46;
    margin-bottom: 25px;
    padding: 0 20px;
  }

  &--form {
    background: $lighter-table;
    padding: 20px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &--label {
    display: block;
    font-size: 14px;
    font-weight: 900;
  }

  &--textarea {
    display: block;
    margin: 15px 0;
    width: 100%;
    border-radius: 8px;
    border: none;
    min-height: 180px;
    padding: 15px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    font-family: "Gotham 4r", "Gotham A", "Gotham B", sans-serif;
  }

  &--input {
    padding: 15px 40px;
    border-radius: 25px;
    background-color: $cyan;
    color: $white;
    border: none;
    font-size: 14px;
    font-weight: bold;
    align-self: flex-end;
  }

  &--wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #f3f2ee;
    padding: 15px;
    align-items: center;

    * {
      margin: 0 10px;
    }

    *:first-child {
      margin-left: 0;
    }

    *:last-child {
      margin-right: 0;
    }
  }

  &--text {
    font-size: 14px;
    font-weight: bold;
  }

  .col-icon::after {
    width: 45px;
    height: 45px;
  }

  &--open-button {
    font-size: 11px;
    font-weight: bold;
    background: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    flex: 0 0 auto;
  }

  &--message-sent-text {
    font-size: 18px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: $charcoal-grey;
  }

  .sol-modal-close {
    .col-icon {
      width: 20px;
      height: 20px;
      margin: 5px;

      &::after {
        width: 20px;
        height: 20px;
      }
    }
  }
}
