@mixin icon-size($height, $width) {
  max-height: $height;

  &::after {
    height: $height;
    width: $width;
  }
}

.col-icon {
  display: inline-block;

  &::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    height: 15px;
    width: 15px;
  }
}

.col-icon-invert {
  filter: invert(100%);
}

.col-icon__red-arrow {
  &::after {
    display: block;
    background-image: url('#{$icons-path}/arrow-red.svg');
  }
}

.col-icon-robot {
  &::after {
    background-image: url('#{$icons-path}/science-fiction-robot.svg');
  }
}

.col-icon-filter {
  &::after {
    background-image: url('#{$icons-path}/filter.svg');
  }
}

.col-icon-common-file-text-edit_red {
  &::after {
    background-image: url('#{$icons-path}/common-file-text-edit_red.svg');
  }
}

.col-icon-newspaper-read-woman_grey {
  &::after {
    background-image: url('#{$icons-path}/newspaper-read-woman_grey.svg');
  }
}

.col-icon-laptop-lock {
  &::after {
    background-image: url('#{$icons-path}/laptop-lock.svg');
  }
}

.col-icon-drawer-download {
  &::after {
    background-image: url('#{$icons-path}/drawer-download.svg');
  }
}

.col-icon-common-file-text-edit_grey {
  &::after {
    background-image: url('#{$icons-path}/common-file-text-edit_grey.svg');
  }
}

.col-icon-newspaper-read-woman_red {
  &::after {
    background-image: url('#{$icons-path}/common-file-text-edit_grey.svg');
  }
}

.col-icon-check {
  &::after {
    background-image: url('#{$icons-path}/check.svg');
  }
}

.col-icon-arrow-right {
  &::after {
    background-image: url('#{$icons-path}/arrow-right.svg');
  }
}

.col-icon-arrow-right-purple {
  &::after {
    background-image: url('#{$icons-path}/arrow-right-purple.svg');
  }
}

.col-icon-check-purple {
  &::before {
    background-image: url('#{$icons-path}/check-violet.svg');
  }
}

.col-icon-close {
  &::after {
    background-image: url('#{$icons-path}/close.svg');
  }
}

.col-icon-messages-bubble-warning {
  &::after {
    background-image: url('#{$icons-path}/messages-bubble-warning.svg');
  }
}

.col-icon-lock-cyan {
  &::after {
    background-image: url('#{$icons-path}/lock-6-cyan.svg');
  }
}
