.col-mega-menu__content {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 20px 20px 10px;

  & .sv-text-portlet {
    border-bottom: 1px solid $warm-white;
    margin-bottom: 10px;
    min-height: 51px;

    .sv-portlet-toolbar {
      font-weight: 600;
    }
  }

  & h1.font-heading-1,
  & h2.font-heading-2,
  & h3.font-heading-3,
  & h4.font-heading-4,
  & p.font-normal {
    margin: 0;
  }

  .sv-html-portlet {
    display: none;

    p {
      b {
        font-weight: 700;
      }
    }

    .sv-editing-mode & {
      display: block;
    }
  }
}
