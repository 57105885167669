.col-expandable {
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  &__header {
    @include no-appearance;

    position: relative;
    padding: 23px 75px 23px 20px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    margin-bottom: -1px;
    background-color: $warm-white;
    font-family: $header-font;
    font-weight: 900;

    & span {
      padding-top: 5px;
      display: inline-block;
    }

    &:hover {
      .col-expandable__name {
        position: relative;

        &::after {
          display: block;
          content: '';
          height: 1px;
          width: 100%;
          background-color: $red;
          position: absolute;
        }
      }
    }

    &__icon {
      display: block;
      width: 35px;
      height: 40px;
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -19px;

      &::after,
      &::before {
        content: "";
        display: block;
        background-color: $red;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 17px;
        height: 2.3px;
        margin-left: -10px;
        margin-top: -3px;
        @include transition(all);
      }

      &::after {
        @include rotate(90deg);

        height: 3px;
      }
    }

    &--active {
      & .col-expandable__header__icon {
        &::after {
          @include rotate(0deg);
        }
      }

      background-color: $red;
      color: $white;
    }

    &--hexagon {
      position: relative;
      width: 50px;
      height: 28.87px;
      background-color: $white;
      float: right;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
      }

      &::before {
        bottom: 100%;
        border-bottom: 14.43px solid $white;
      }

      &::after {
        top: 100%;
        width: 0;
        border-top: 14.43px solid $white;
      }
    }
  }

  &__content,
  .sv-decoration-content {
    padding: 10px 20px;
    display: none;
    border: 1px solid $warm-white;
    background-color: white;
  }

  &-hexagon__wrapper {
    display: block;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  & &__child {
    position: absolute;
    right: 90px;
    top: 17px;
    padding: 10px 20px;
    background: $white;
    color: $black;
    text-align: center;
    border-radius: 2px;
    font-weight: normal;

    @media #{$xs} {
      top: 7px;
      left: 20px;
      right: unset;
      padding: 0.25em;
      font-size: 10px;
    }
  }
}
