.col-cost-counter {
  &--slider {
    .vue-slider-component .vue-slider {
      background-color: $warm-white;
      height: 10px !important;
    }

    .vue-slider-component .vue-slider-process {
      background-color: $pink;
    }

    .vue-slider-horizontal {
      margin-bottom: 10px;
    }

    .vue-slider-component.vue-slider-horizontal .vue-slider-dot {
      width: 54px !important;
      height: 54px !important;
      top: -23px !important;
      left: -20px;
      margin-bottom: 10px;
    }

    .vue-slider-dot-handle {
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 6px 5px 30px 0 rgba(0, 0, 0, 0.09);
    }

    .vue-slider-tooltip {
      display: none !important;
    }
  }

  &--heading {
    h2 {
      font-size: 22px;
      font-weight: 900;
      line-height: 32px;
      letter-spacing: 0.18px;
      color: $charcoal-grey;
    }
  }

  &--preamble {
    margin-bottom: 70px;

    span {
      font-size: 22px;
      line-height: 1.36;
      color: $charcoal-grey;
    }
  }

  &--subheading {
    margin-bottom: 48px;

    span {
      font-size: 20px;
      font-weight: 900;
      color: $charcoal-grey;
      line-height: 26px;
      letter-spacing: 0.2px;
    }
  }

  &--monthly {
    padding-bottom: 24px;

    span {
      font-size: 20px;
      color: $warm-black;
    }
  }

  &__total {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.6;
    color: $charcoal-grey;
  }

  &__cost {
    font-size: 16px;
    line-height: 0.88;
    color: $warm-black;

    &--wrapper {
      text-align: right;
      padding-top: 14px;
      margin-right: 35px;

      @media #{$lg} {
        margin-right: 0;
      }
    }
  }

  &--input {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 35px;

    @media #{$lg} {
      margin-right: 0;
    }

    & span {
      margin-bottom: 15px;
      margin-left: 7px;
    }

    &-mobile {
      & input {
        @include no-appearance();

        width: 100%;
        background-color: $white;
        height: 50px;
        border-radius: 2px;
        font-size: 19px;
        padding: 0 10px;
      }
    }

    & input {
      @include no-appearance();

      background-color: $white;
      height: 50px;
      border-radius: 2px;
      font-size: 19px;
      max-width: 122px;
      padding: 0 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  &--above {
    position: absolute;
    color: $darker-red;
    width: 15%;

    @media #{$md} {
      width: 40%;
    }
  }
}
