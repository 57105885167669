.col-error-page {
  padding: 80px 0;
  margin: 25px auto;

  @media #{$md} {
    padding: 10px 0;
    text-align: center;
  }

  h1 {
    margin-top: 80px;
    margin-bottom: 83px;
    font-size: 40px;
    font-weight: 900;

    @media #{$md} {
      font-size: 28px;
      margin-top: 10px;
      margin-bottom: 28px;
    }
  }

  .sv-column-12 {
    display: flex !important;

    @media #{$md} {
      justify-content: space-around;
    }
  }

  .col-clickable-element {
    .sv-decoration-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 160px;
      margin-right: 62px;

      @media #{$md} {
        margin: 0 10px;
      }
    }

    .sv-image-portlet {
      max-width: 60px;
      width: 100%;
      height: 60px;

      @media #{$md} {
        max-width: 33px;
        height: 33px;
      }
    }

    .sv-text-portlet {
      font-size: 18px;

      @media #{$md} {
        font-size: 14px;
      }

      p {
        text-align: center;
        margin-top: 8px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
