.col-action-link {
  margin: 10px 0 10px 0;

  a {
    display: table-cell;
    vertical-align: middle;
    text-decoration: none;
    color: $white;
    border-radius: 35px;
    width: 100%;
  }

  &__purple {
    background-color: $purple;

    &:hover {
      background-color: $dark-purple;
    }
  }

  &__blue {
    background-color: $cyan;

    &:hover {
      opacity: 0.75;
    }
  }

  &__noraml {
    padding: 15px 28px 15px 28px;
    font-size: 14px;
    max-height: 50px;
    height: 50px;
    min-width: 140px;
    font-weight: 500;
    position: relative;
    text-align: center;

    & img {
      height: 18px;
      width: 20px;

      @media #{$sm} {
        max-height: 14px !important;
      }
    }

    &.col-action-link__left {
      padding: 15px 28px 15px 50px;
      text-align: initial;

      img {
        position: absolute;
        width: 22px;
        height: 22px;
        left: 16px;
        top: 13px;
      }

      @media #{$sm} {
        padding: 15px 28px 15px 45px;

        img {
          top: 14px;
        }
      }
    }

    &.col-action-link__right {
      padding: 15px 55px 15px 28px;
      text-align: initial;

      img {
        position: absolute;
        width: 22px;
        height: 22px;
        right: 18px;
        top: 13px;
      }
    }

    @media #{$sm} {
      padding: 10px 17px 10px 17px;
      font-size: 11px;
      height: 40px;
      max-height: 40px;
    }
  }

  &__large {
    padding: 19px 32px 19px 32px;
    font-size: 16px;
    height: 65px;
    min-width: 230px;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    position: relative;

    /* stylelint-disable-next-line no-descending-specificity */
    & img {
      height: 25px;

      @media #{$sm} {
        max-height: 18px !important;
      }
    }

    &.col-action-link__left {
      padding: 19px 32px 19px 75px;

      & img {
        position: absolute;
        left: 25px;
        top: 18px;
      }

      @media #{$sm} {
        padding: 19px 32px 19px 60px;

        & img {
          top: 17px;
        }
      }
    }

    &.col-action-link__right {
      padding: 19px 75px 19px 35px;

      & img {
        position: absolute;
        top: 18px;
        right: 25px;
      }

      @media #{$sm} {
        padding: 19px 60px 19px 35px;
      }
    }

    @media #{$sm} {
      padding: 15px 28px 15px 28px;
      font-size: 14px;
      max-height: 50px;
      height: 50px;
    }
  }

  &__small {
    padding: 10px 17px 10px 17px;
    font-size: 11px;
    height: 40px;
    max-height: 40px;
    min-width: 75px;
    font-weight: 500;
    line-height: 0.91;
    text-align: center;
    position: relative;

    &.col-action-link__left {
      padding: 10px 17px 10px 40px;
      text-align: initial;

      img {
        position: absolute;
        width: 18px;
        height: 18px;
        left: 14px;
        top: 10px;
      }
    }

    &.col-action-link__right {
      padding: 10px 40px 10px 17px;
      text-align: initial;

      img {
        position: absolute;
        width: 18px;
        height: 18px;
        right: 14px;
        top: 10px;
      }
    }
  }
}
