.col-bullet-list-app {
  position: relative;
  margin-bottom: 40px;
  padding-left: 90px;

  &__bg {
    content: '';
    border-radius: 50%;
    display: block;
    width: 57px;
    height: 57px;
    text-align: center;
    float: left;
    margin-right: 30px;
    position: absolute;
    left: 0;
  }

  &__purple {
    background-color: $purple;
  }

  &__blue {
    background-color: $cyan;
  }

  &__red {
    background-color: $red;
  }

  &__pink {
    background-color: $pink;
  }

  &__icon {
    height: 32px;
    margin-top: 12px;
  }

  h2 {
    margin-bottom: 5px;
    font-size: 18px;
  }

  p {
    margin-top: 0;
    font-size: 15px;
  }
}
