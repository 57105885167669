.col-mega-menu-link-list {
  position: absolute;
  left: 280px;
  top: 0;
  right: 0;
  padding: 40px 70px 35px;
  z-index: 2;
  max-height: calc('100vh - 110px');
  overflow: auto;

  & ul {
    @include no-list-style;
  }
}

.col-mega-menu-column {
  flex-direction: column !important;
  align-items: flex-start;

  & li {
    width: 100%;
  }
}

.col-mega-menu-link-category {
  color: $red;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.col-mega-menu-link-close {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  height: 35px;
  width: 70px;
  z-index: 10;

  &__icon {
    @include no-appearance;
    $size: 35px;

    position: relative;
    background-color: $warm-white;
    display: inline-block;
    height: $size;
    width: $size * 2;
    border-top-left-radius: $size * 2;
    border-top-right-radius: $size * 2;
    cursor: pointer;

    .col-icon {
      position: absolute;
      left: 44%;
      top: 35%;
      transform: translate(-50%, -50%);

      @include rotate(270deg);

      &::after {
        fill: $red;
      }
    }
  }
}
