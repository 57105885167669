.col-mega-menu-top-link {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 4px solid fadeout($white, 70%);
  margin-bottom: 20px;
  margin-left: -10px;
  padding-left: 10px;

  &:hover {
    color: $black;

    & span {
      text-decoration: underline;
    }
  }

  i.fal,
  i.far {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 5px 3px 14px 0 rgba(0, 0, 0, 0.1);
    font-size: 17px;
    width: 31px;
    height: 31px;
    position: relative;
    top: -1px;
  }
}
