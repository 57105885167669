.col-top-menu-item,
.col-mega-menu-item {
  display: block;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: $white;
  position: relative;
  padding: 14px 40px;
  height: 50px;
  border-radius: 2px;
  align-items: center;
  text-align: center;
  @include transition(background-color);

  @media #{$lg} {
    letter-spacing: 0;
  }

  &::after {
    content: "";
    display: block;
    background-color: $warm-white;
    opacity: 0.3;
    position: absolute;
    width: 1px;
    height: 25px;
    right: 0;
    top: 12px;
  }

  &--active {
    color: $white;
    text-decoration: none;
  }

  & &__name {
    z-index: 1;
    position: relative;
  }

  &:hover {
    background-color: $darkest-red;

    &::after {
      display: none;
    }
  }

  & i.far {
    @include transition(all);

    margin-left: 10px;
    font-size: 12px;
  }

  &[aria-expanded='true'] {
    background-color: $darkest-red;

    &::after {
      display: none;
    }
  }

  [data-whatinput=keyboard] &:focus {
    outline-offset: 5px;
  }
}
