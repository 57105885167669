.col-documentarchive-list {
  padding: 0 100px;

  @media #{$sm} {
    padding: 0 10px;
  }

  &__btns {
    margin-bottom: 26px;
  }

  &__back {
    @include no-appearance();
    @extend .col-icon;
    @extend .col-icon-arrow-right-purple;

    font-size: 16px;
    font-weight: 500;
    color: $purple;
    position: relative;
    padding-left: 27px;

    &::after {
      height: 14px;
      width: 22px;
      transform: rotate(180deg);
      position: absolute;
      left: 0;
      top: 3px;
    }
  }

  &__category {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.19;
    color: $charcoal-grey;
  }

  &__filter {
    position: relative;
    float: right;

    button {
      @include no-appearance();

      border: 1px solid #e0e4e8;
      padding: 8px 15px;
      background-color: $white;
      border-radius: 26.5px;

      &:hover {
        background-color: darken($white, 5%);
      }

      & span {
        color: $charcoal-grey;
        font-size: 12px;
        font-weight: 900;
        display: block;
        float: right;
        margin-top: 1px;
        margin-left: 10px;
      }
    }
  }
}
