.col-mega-menu-sub-items {
  @include no-list-style;
  @include transition(min-height);

  width: 280px;
  background-color: $warm-white;
  display: inline-block;
  padding-bottom: 170px;
  padding-top: 31px;

  &::after {
    content: '';
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 400px;
  }
}
