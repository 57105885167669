/* decorations/forms.scss */
.col-form,
.col-form-decoration,
.sv-form-portlet,
.sv-dbform-portlet {
  $form-bg: $warm-white;
  $form-divider: $warm-white;
  $form-border: transparent;

  padding: 20px 20px 40px;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid $form-input-border;
  z-index: 1;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
    background-color: $form-bg;
  }

  @media #{$md} {
    padding: 20px;
  }

  @media #{$sm} {
    padding-right: 10px;
    padding-left: 10px;
  }

  & h2 {
    margin-top: 0;
  }

  & form {
    & textarea {
      height: auto;
    }

    & select {
      @extend .col-input-select;
    }

    & select,
    & textarea,
    & input[type='email'],
    & input[type='number'],
    & input[type='password'],
    & input[type='tel'],
    & input[type='text'],
    & input[type='url'] {
      @extend .col-input-text;

      margin-bottom: 15px;

      & ~ span.font-normal {
        margin-top: 10px;
      }

      &:invalid {
        @extend .col-input-text-invalid;
      }
    }

    .sv-form-subalternativefield {
      & > div {
        float: none;
      }
    }

    & .sv-form-datefield {
      & > div {
        width: 50%;
        margin: 0 !important;
        // Can't move rule.
        // stylelint-disable-next-line
        & select {
          min-width: 100%;
          margin-bottom: 10px;
        }

        &:nth-of-type(1) {
          padding-right: 5px;
        }

        &:nth-of-type(2) {
          padding-left: 5px;
        }
      }
    }

    & .sol-form-field {
      margin-bottom: 10px;
    }

    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & > label {
        @extend .col-input-label;

        margin-bottom: 14px;
      }

      & > span {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }

    & br {
      display: none;
    }

    .font-normal {
      display: block;
    }

    & .sv-formField {
      margin-bottom: 0;

      @media #{$sm} {
        width: 100%;
        padding: 0;
      }
    }

    & input[type='checkbox'] {
      @extend .col-input-checkbox;
    }

    & input[type='radio'] {
      @extend .col-input-radio;
    }

    & button[type='submit'],
    & input[type='submit'] {
      @extend .col-input-btn;

      margin-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
      width: 140px;

      & > i.fa,
      & > i.far,
      & > i.fal,
      & > i.fab,
      & > i.fas {
        margin-right: 11px;
        margin-left: -15px;
      }
    }

    & .sol-form-divider {
      @extend .col-input-divider;
    }
  }
}
