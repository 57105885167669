footer {
  a {
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    a {
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
 
      img.sv-linkicon {
        display: none;
      }
    }
  }
}
