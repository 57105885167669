.col-mobile {
  &-top {
    display: none;
    padding-top: 10px;
    padding-bottom: 8px;

    @media #{$sm} {
      display: block;
    }

    &__logo {
      height: 40px;
      widows: 139px;
    }
  }

  &-bottom {
    display: none;

    a,
    button {
      color: $header-red;
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
    }

    button {
      background: none;
      border: 0;
      font-size: 12px;
      padding: 0 20px 0 10px;
    }

    @media #{$sm} {
      display: block;
      background-color: $white;
      top: 95px;
      height: 50px;
      width: 100%;

      & > div {
        display: flex;

        .col-mobile-bottom__item {
          display: inline-block;
          flex-basis: 33.3%;
          padding: 14px 0% 14px 8%;
          border-left: 1px solid#f1dfe2;

          &:first-child {
            border-left: none;
          }
        }

        .col-mobile-bottom__img {
          height: 18px;
          width: 18px;
          position: relative;
          top: 3px;
          left: -5px;
        }
      }
    }
  }

  &-header {
    display: none;
    text-align: right;
    background-color: $warm-white;
    padding: 5px;

    &.col-active {
      display: block;
    }

    .sv-searchform2 {
      &-input[type=text] {
        height: 50px;
        padding: 15px;
        border-radius: 2px;
        margin: 0;
      }

      &-portlet {
        height: 60px;
        margin: 0 !important;
      }

      &-form {
        height: 60px;
        margin: 0 !important;
        position: relative;
        display: block;
        padding: 5px;

        .sv-searchform2-result-container {
          border-radius: 2px;
          width: 100% !important;
          top: 60px !important;
          left: 0 !important;
        }

        .sv-searchform2-content-container {
          text-align: left;
          width: 100% !important;
          border-radius: 2px;

          & > div {
            width: 100% !important;
          }

          & > h3 {
            margin-top: -20px !important;
          }
        }

        .sv-searchform2-search-hit {
          text-align: left;

          & a {
            text-decoration: none;
            font-size: 16px;
          }
        }

        .col-top-search-field__img {
          top: -36px;
        }

        .sv-searchform2-search-hit:last-child {
          & a {
            color: $cyan;
            text-decoration: underline;
            cursor: pointer;
          }

          &:hover {
            & a {
              color: $white;
            }
          }
        }

        .col-top-search-field__button {
          bottom: 47px;
        }

        .col-search-clear {
          display: none;
          height: 21px;
          width: 22px;
          top: -45px;
          right: 15px;
          font-size: 18px;
          border: 0;
          background-color: $white;
          position: relative;
          color: $black;
        }
      }
    }
  }
}

.col-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 185px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $warm-black;
  opacity: 0.6;
  z-index: 2;
  cursor: pointer;
}
