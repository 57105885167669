.col-cost-counter {
  background-color: $light-gray;
  background-repeat: no-repeat;
  background-position: left;
  padding: 100px 30px 100px 30px;
  background-size: 50%;

  @media #{$lg} {
    background-position: top 75px left -60px;
    background-size: 60%;
  }

  @media #{$sm} {
    background-position: bottom 0 center;
    padding: 10px 10px 300px 10px;
    background-size: 80%;
  }

  @media #{$xs} {
    background-size: 120%;
  }

  .col-grid {
    width: 100%;

    .col-row {
      justify-content: flex-end;
    }
  }
}
