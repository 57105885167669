.col-light-bg {
  background-color: $light-gray;
  padding: 80px;
  margin-bottom: 10px;

  @media #{$sm} {
    padding: 80px 5px;
  }
}

.col-product-wide-content {
  &.sv-fluid-grid {
    max-width: 1440px !important;
  }
}

.col-padding-bottom {
  padding-bottom: 60px;
}
