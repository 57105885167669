.col-contacts-row {
  justify-content: center !important;
  flex-wrap: nowrap !important;
  margin-top: 99px;
  margin-bottom: 197px;

  .sv-layout {
    margin: 0 45px !important;
    text-align: center;
  }

  & p.font-normal {
    margin: 0;
  }

  & strong {
    margin-bottom: 8px;
  }

  .sv-image-portlet {
    margin-bottom: 19px;
  }
}
