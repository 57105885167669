.col-shortcut {
  width: 55px;
  height: auto;
  min-height: 62px;

  &-menu {
    background-color: $darker-red;
    padding-top: 47px;
    padding-bottom: 40px;

    .sv-layout {
      margin-bottom: 0 !important;
    }

    .sv-row:first-child {
      display: flex !important;
      flex-wrap: wrap;

      & > div {
        display: inline-flex !important;
        flex-basis: 25% !important;
        margin-bottom: 20px;

        @media #{$md} {
          flex-basis: 50% !important;
        }

        @media #{$sm} {
          flex-basis: 100% !important;
        }

        &:first-child {
          .col-hexagon {
            & img {
              width: 18.62px;
              height: 30.14px;
              top: 10px;
              left: 18px;
            }
          }
        }

        &:nth-child(2) {
          .col-hexagon {
            & img {
              width: 29.5px;
              height: 30px;
              top: 11px;
              left: 12px;
            }
          }
        }

        &:nth-child(3) {
          .col-hexagon {
            & img {
              width: 21px;
              height: 30px;
              top: 11px;
              left: 17px;
            }
          }
        }

        &:nth-child(4) {
          .col-hexagon {
            & img {
              width: 28.5px;
              height: 30px;
              top: 11px;
              left: 14px;
            }
          }
        }
      }
    }

    .col-shortcut-text {
      display: flex !important;
      margin-left: 20.8px !important;
      flex-direction: column !important;
      position: relative;

      & a {
        color: $white;
        text-decoration: none;
      }

      & h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 11px !important;
        color: $white;
        margin: 0 0 8px 0;
      }

      & p {
        font-size: 14px;
        font-weight: 300;
        color: $white;
        margin: 0;
      }

      & img {
        min-width: 17px;
        height: 15px;
        margin-right: 10px;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10 and above
        width: 100%;
      }
    }
  }

  &-item {
    padding: 5px 0;

    @media #{$sm} {
      padding: 8px 0;
    }

    .col-hexagon {
      position: relative;
      padding-right: 35px;

      &-left {
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-right: 27.5px solid $lighter-gray;
        height: 62px;
        width: 0;
        margin: 0;
        position: absolute;
        top: -6px;
        bottom: -20px;
        left: 0;
      }

      &-right {
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 27.5px solid $light-gray-two;
        height: 62px;
        width: 0;
        margin: 0;
        position: absolute;
        top: -6px;
        left: 27.5px;
        bottom: -20px;
      }

      & img {
        width: 28.5px;
        height: 30px;
        position: relative;
        top: 7px;
        z-index: 1;
        left: 16px;
      }
    }
  }
}
