.col-download-btn {
  margin-top: 12px;

  .col-icon {
    background-color: $cyan;
    border-radius: 50%;
    width: 25px;
    height: 24px;
    display: block;
    float: left;
    text-align: center;
    margin-right: 6px;

    &::after {
      // display: inline-block;
    }
  }

  & a {
    text-decoration: none;
    line-height: 26px;
  }
}
