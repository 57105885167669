.col-header {
  background-color: $red;

  &-top {
    display: flex !important;
    justify-content: space-around;
    padding: 22px 0 18px 0;
    align-items: center;
    vertical-align: middle;

    & > div {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      &:not(:nth-child(1)) {
        text-align: right;
      }
    }

    & > div:nth-child(3) {
      flex-grow: 0;
    }

    .sv-searchform2 {
      &-input[type=text] {
        height: 50px;
        padding: 15px;
        border-radius: 2px;
      }

      &-portlet {
        height: 50px;
        margin: 0 !important;
      }

      &-form {
        height: 50px;
        margin: 0 !important;
        position: relative;
        display: block;

        .sv-searchform2-result-container {
          border-radius: 2px;
          width: 100% !important;
          top: 50px !important;
          left: 0 !important;
        }

        .sv-searchform2-content-container {
          text-align: left;
          width: 100% !important;
          border-radius: 2px;

          & > div {
            width: 100% !important;
          }

          & > h3 {
            margin-top: -20px !important;
          }
        }

        .sv-searchform2-search-hit {
          text-align: left;

          & a {
            text-decoration: none;
            font-size: 16px;
          }
        }

        .sv-searchform2-search-hit:last-child {
          & a {
            color: $cyan;
            text-decoration: underline;
            cursor: pointer;
          }

          &:hover,
          &:focus {
            & a {
              color: $white;
            }
          }
        }

        .col-search-clear {
          display: none;
          height: 21px;
          width: 22px;
          top: -42px;
          right: 15px;
          font-size: 18px;
          border: 0;
          background-color: $white;
          position: relative;
          color: $black;
        }
      }
    }
  }
}
