.col-tabs-window {
  display: flex;

  .sv-decoration-content {
    position: relative;
    width: 100%;
    box-shadow: 2px 2px 10px 0 rgba(37, 37, 37, 0.05);
    min-height: 350px;
    background-color: $white;
    padding: 35px 50px;

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      margin-bottom: 3px;
      margin-top: 0;
      word-break: break-all;
    }

    .sv-row:not(:last-of-type) {
      border-bottom: 1px solid $warm-white;
      padding-bottom: 47px;
      margin-bottom: 15px;
    }

    &::before {
      content: '';
      display: block;
      background-color: $white;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: absolute;
      left: -25px;
      top: 35px;
      box-shadow: 2px 2px 10px 0 rgba(37, 37, 37, 0.05);
    }

    &::after {
      content: '';
      display: block;
      background-color: $white;
      width: 45px;
      height: 90px;
      position: absolute;
      left: 0;
      top: 20px;
    }

    .sv-image-portlet {
      margin-top: 15px;
    }
  }

  &__tab {
    @include outline-focus;
  }

  @media #{$md} {
    display: block;

    .sv-decoration-content {
      display: none;
    }

    &__tab {
      background-color: $white;
      margin-bottom: 19px;
      padding: 5px 20px;
      z-index: 1;
    }
  }
}

.col-tabs-window__buttons {
  display: flex;
  flex-direction: column;
  width: 412px;
  margin-right: 40px;

  .col-tabs-window-button {
    @include no-appearance;
    @include outline-focus;
    @extend .col-icon;
    @extend .col-icon-arrow-right;

    position: relative;
    height: 122px;
    background-color: $white;
    box-shadow: 2px 2px 10px 0 rgba(37, 37, 37, 0.05);
    padding: 0 75px 0 35px;
    font-family: "Gotham 4r", "Gotham A", "Gotham B";
    font-size: 16px;
    font-weight: 900;
    line-height: 1.19;
    text-align: left;
    margin-bottom: 19px;
    counter-increment: number-of-tabs;
    display: flex;
    align-items: center;

    &.col-has-counter::before {
      content: counter(number-of-tabs);
      width: 37px;
      display: inline-block;
      flex: 0 0 auto;
      color: $warm-white;
    }

    &::after {
      position: absolute;
      right: 35px;
      width: 31px;
      height: 20px;
      flex: 0 0 auto;
    }

    &--active {
      @extend .col-icon-arrow-right-purple;

      color: $purple-2;
      box-shadow: 2px 2px 40px 0 rgba(37, 37, 37, 0.2) !important;

      &.col-has-counter::before {
        color: $purple-2;
      }

      @media #{$md} {
        margin-bottom: 2px;
      }
    }
  }

  @media #{$md} {
    width: 100%;
    margin-top: 15px;
  }
}
