@mixin generate-columns($n, $selector) {
  @for $i from 1 through $n {
    $col: "#{$selector}-#{$i}";
    $push: "#{$selector}-push-#{$i}";
    $pull: "#{$selector}-pull-#{$i}";

    #{$col} {
      flex: 1 0 decimal-round($i * 100% / $n, 3);
      max-width: decimal-round($i * 100% / $n, 3);
    }

    #{$push},
    .sv-push-#{$i} {
      position: relative;
      left: decimal-round(($i * 100% / $n), 3);
    }

    #{$pull},
    .sv-pull-#{$i} {
      position: relative;
      left: -(decimal-round(($i * 100% / $n), 3));
    }
  }
}

@mixin generate-row($row-class, $col-class) {
  .#{$row-class} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -($col-spacing/2);
    margin-right: -($col-spacing/2);
    justify-content: flex-start;

    @media #{#{$md}} {
      margin-left: -($col-spacing/4);
      margin-right: -($col-spacing/4);
    }

    &.#{$row-class}-reverse {
      flex-direction: row-reverse;
    }

    &.#{$row-class}-center {
      justify-content: center;
    }

    &.#{$row-class}--narrow {
      margin-left: -($col-spacing/4);
      margin-right: -($col-spacing/4);

      & [class*='#{$col-class}'] {
        padding-left: ($col-spacing/4);
        padding-right: ($col-spacing/4);
      }
    }

    @include generate-columns($col-size, '*.#{$col-class}');
  }
}

.col-grid,
.sv-grid-main.sv-fluid-grid,
.sv-grid-main.sv-fixed-fluid-grid {
  padding-left: 20px;
  padding-right: 20px;
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;

  @media #{$lg} {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media #{$xl}, #{$lg-strict} {
    @include generate-row(sv-row, sv-column);
  }

  @media #{$xl} {
    @include generate-row(col-row, col-col-xl);
  }

  @media #{$lg-strict} {
    @include generate-row(col-row, col-col-lg);
  }

  @media #{$md-strict} {
    @include generate-row(col-row, col-col-md);
  }

  @media #{$sm-strict} {
    @include generate-row(col-row, col-col-sm);
  }

  @media #{$xs} {
    @include generate-row(col-row, col-col-xs);
  }

  & *[class*='col-col-'],
  & *[class*='sv-column-'] {
    flex: 1 0 100%;
    max-width: 100%;
    padding-left: ($col-spacing/2);
    padding-right: ($col-spacing/2);

    @media #{$md} {
      padding-left: ($col-spacing/4);
      padding-right: ($col-spacing/4);
    }
  }

  .sv-row {
    & *[class*='sv-column-'] {
      width: auto !important;
      margin-left: 0;
      margin-right: 0;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.col-show-lg,
.col-show-md,
.col-show-sm,
.col-show-xl,
.col-show-xs {
  display: none !important;
}

@media #{$xl} {
  .col-hide-xl {
    display: none !important;
  }

  .col-show-xl {
    display: inherit !important;
  }
}

@media #{$lg-strict} {
  .col-hide-lg {
    display: none !important;
  }

  .col-show-lg {
    display: inherit !important;
  }
}

@media #{$md-strict} {
  .col-hide-md {
    display: none !important;
  }

  .col-show-md {
    display: inherit !important;
  }
}

@media #{#{$sm}-strict} {
  .col-hide-sm {
    display: none !important;
  }

  .col-show-sm {
    display: inherit !important;
  }
}

@media #{$xs} {
  .col-hide-xs {
    display: none !important;
  }

  .col-show-xs {
    display: inherit !important;
  }
}
