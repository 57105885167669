.col-locked-link {
  position: relative;
  padding-left: 25px;

  img {
    display: none;
  }

  @extend .col-icon;
  @extend .col-icon-lock-cyan;

  &::after {
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    &::after {
      filter: brightness(0);
    }
  }
}
