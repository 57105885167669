.col-footer {
  background-color: $red;
  padding: 60px 0 40px 0;
  color: $white;

  & h1,
  p,
  a {
    color: $white;
    text-decoration: none;
  }

  .font-normal a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & h1 {
    margin-top: 0;
    margin-bottom: 22px;
    font-size: 18px;
    font-weight: 600;
  }

  & h1,
  p,
  .font-normal a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-top {
    text-align: left;

    & p {
      font-size: 15px;
      line-height: 21px;
    }

    @media #{$md} {
      & > div {
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 990px) {
      & > div:nth-child(3) {
        margin-top: 100px;
      }
    }

    @media #{$sm} {
      text-align: center;
    }

    .col-footer-subscription {
      &__link {
        display: table;
        padding: 17px 25px 17px 25px;
        background-color: $cyan;
        border-radius: 22px;
        margin-top: 25px;
        font-size: 14px;
        color: $white;

        &:hover {
          text-decoration: none;
        }

        @media #{$sm} {
          display: inline-block;
        }
      }
    }

    .col-footer-logo {

      @media #{$sm} {
        margin-bottom: 25px;
      }

      &__img {
        width: 145px;
        height: 42px;
      }

      &__text {
        line-height: 21px;
      }
    }

    .col-footer-social__media {
      display: block;

      @media #{$sm} {
        display: inline-block;
        margin-bottom: 20px;
        margin-left: 0;
      }

      h1 {
        @media #{$sm} {
          top: 0;
        }
      }

      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 9px;
        margin-right: 15px;
        float: left;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: $white;
        color: #485155;
        font-size: 15px;
        text-align: center;
        line-height: 20px;
        transition-property: opacity;
        transition-duration: 200ms;
        transition-timing-function: ease;

        &:nth-child(2) {
          @media #{$sm} {
            position: relative;
            top: 2px;
          }
        }

        @media #{$sm} {
          float: none;

          &:last-child {
            margin-right: 0 !important;
          }
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &-bottom {
    margin-top: 50px;

    @media #{$sm} {
      text-align: center;
      margin-top: 30px;
    }

    & > div:last-child {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;

      @media #{$md} {
        justify-content: space-around;
      }

      @media #{$sm} {
        flex-direction: column;
      }
    }

    & p {
      font-size: 11px;
    }
  }
}
