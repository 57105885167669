.col-documentarchive-filters {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 10px 0;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.22);
  left: -8px;
  top: 40px;

  .col-documentarchive-filter {
    border-radius: 0;
    border: none;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      color: $white;
      background-color: $red;
    }

    &--active {
      color: $white;
      background-color: $red;
    }
  }
}
